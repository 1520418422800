.report-container {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
  }
  
  .report-header {
    text-align: center;
    color: black;
  }
  
  .master-data {
    /* display: flex;
    justify-content: space-between; */
    margin-bottom: 20px;
    color: black;
  }
  
  .master-item {
    display: flex;
    justify-content: space-between;
    color: black;
  }
  
  .master-item p {
    margin: 5px 10px;
    color: black;
  }
  
  .details-table {
    width: 100%;
    border-collapse: collapse;
    color: black;
  }
  
  .details-table th,
  .details-table td {
    border-bottom: 1px solid #ccc;
    padding: 8px;
    text-align: left;
    color: black;
  }
  
  .details-table th:first-child,
  .details-table td:first-child {
    padding-left: 0;
  }
  
  .total-invoice {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: end;
    gap: 20px;
  }
  