@font-face {
    font-family: SourceSansPro;
    src: url(SourceSansPro-Regular.ttf);
  }
  
  @media print{
    
  }
  
  .clearfix:after {
    content: "";
    display: table;
    clear: both;
  }

  #main_body_invoicepra4{
    position: relative;
    width: 90%;  
    height: 35.7cm; 
    margin: 0 auto; 
    color: #000;
    background: #FFFFFF; 
    font-family: Arial, sans-serif; 
    font-size: 14px; 
    font-family: SourceSansPro;
  }
  
  header {
    padding: 10px 0;
    margin-bottom: 20px;
    /* border-bottom: 1px solid #AAAAAA; */
    border: none;
  }

  #table_prodprnt th,  #table_prodprnt td {
    padding: 10px;
    border-bottom:1px solid rgb(202, 202, 202);
  }
  
  #table_prodprnt th {
    white-space: nowrap;        
    font-weight: normal;
    border-top: 2px solid #000;
    border-bottom: 2px solid #000;
  }
  
  #table_prodprnt tbody tr:last-child td {
    /* border: none; */
    border-top: 2px solid black; 
    border-bottom: 2px solid black; 
  }

  footer{
    position: absolute;
    border-color: #fff;
    color: black;
    bottom: 0;
    top: 31.5cm;
  }