.head{
    text-transform: uppercase;
    color: #2E4169;
    text-align: center;
    margin-top: 50px;
   
}

.font{
    font-size: 14px;
   
}
.bck{
    background-color:  #2E4169;
    color: #fff;
    border-radius: 10px;
    padding: 50px;
   
    
}
.title{
    text-transform: uppercase;
    color: #fff;
    text-align: center;   
   
}
.right{
    text-align: end;
   
}
.subheads{
    color: #2E4169;
    font-size: 20px;
    font-weight: bold;
   
    
}

  @media print
  {
    table { page-break-after:auto }
    tr    { page-break-inside:avoid; page-break-after:auto }
    td    { page-break-inside:avoid; page-break-after:auto }
    thead { display:table-header-group }
    tfoot { display:table-footer-group }
    th div, td div {
      margin-top:-8px;
      padding-top:8px;
      page-break-inside:avoid;
  }
  }
