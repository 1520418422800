#content {
  display: table;
}

#main_div_report{
  background-color: white;
  color: black;
  /* margin-left: 2%;
  margin-right: 4%;
  margin-top: 2%; */
}


#pageFooter {
  display: table-footer-group;
  counter-increment: page;
  content: counter(page);
}

#pageFooter:after {
  counter-increment: page;
  content: counter(page);
}

#pageFooter:after {
  counter-increment: page;
  content:"Page " counter(page);
  left: 0; 
  top: 100%;
  white-space: nowrap; 
  z-index: 20px;
  -moz-border-radius: 5px; 
  -moz-box-shadow: 0px 0px 4px #222;   
}

/* div p {
  margin-top: -18px;
} */

body{
  counter-reset: page;
  width:100%;
}
.table{
  border: solid rgb(255, 255, 255) !important;
  border-width: 1px 0 0 1px !important;
}
#report_table thead th { 
  border-bottom: 2px solid #000;
  border-top: 2px solid #000;
  font-family: Arial, sans-serif;
  padding-top: 8px;
  padding-bottom: 6px;
  font-size: 16px; }
.th, .td{
  /* border: solid #000 !important; */
  border-width: 0 1px 1px 0 !important;
  border: solid #806c8c !important;
}


#report_table{
  border-collapse: collapse;
}

#date_td{
  /* width: 6%; */
  width: 2%;
  font-family: Arial, sans-serif;
}

#vno_td{
  /* width: 7%; */
  width: 3%;
}

#type_td{
  /* width: 5%; */
  width: 2%;
} 

#cheqdate_td{
  width: 12%;
  font-family: Arial, sans-serif;
}

#naration_td{
  width: 20%;
}

#debit_td{
  width: 8%;
  font-family: Arial, sans-serif;
}

#credit_td{
  width: 8%;
  font-family: Arial, sans-serif;
}

#balance_td{
  width: 10%;
  font-family: Arial, sans-serif;
}

#footer_balance{
  border-top: 2px solid #000;
}

#footer_td{
  padding-top: 6px;
  padding-bottom: 6px;
  margin-left: 1px;
  border-bottom: 2px solid #000;
  /* font-size: 17px; */
}

.textAlign-center{
  text-align: center;
}

@media print{
  #report_table{
    border: solid #fff !important;
    border-width: 1px 0 0 1px !important;
    width: 100%;
  }
  table thead th {  
    border-bottom: 2px solid #000; 
    border-top: 2px solid #000;
    font-family: Arial, sans-serif;
    padding-top: 8px;
    padding-bottom: 8px;
    
  }
}

@media screen {
  div.divFooter {
    display: none;
  }
}

@media print{
  div.divFooter {
    position: fixed ;
    bottom: 0;
  }
}

.page-break-ledger { display: none; }
@media print {
    .size {
      @page {
        size: A4
    }
    }

    .page-break-ledger {
      page-break-before: always;
      display: block;
      position: absolute;
      bottom: 40px;
    }
}
