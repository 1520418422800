.report-container {
    width: 80%;
    margin: 20px auto;
    font-family: Arial, sans-serif;
    color: #333;
  }
  
  h1 {
    text-align: center;
    margin-bottom: 20px;
    color: #000;
  }
  
  .invoice {
    margin-bottom: 30px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    padding: 20px;
    background-color: #fff;
  }
  
  .invoice-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    border-bottom: 1px solid #000;
    padding-bottom: 10px;
  }
  
  .header-section {
    display: flex;
    flex-direction: column;
  }
  
  .header-section span {
    margin-bottom: 5px;
  }
  
  .invoice-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .invoice-table th,
  .invoice-table td {
    border-bottom: 1px solid #000;
    padding: 4px;
    text-align: left;
  }
  
  .invoice-table th {
    background-color: #f4f4f4;
    font-weight: bold;
    color: #000;
  }
  
  .invoice-total td {
    font-weight: bold;
    color: #000;
  }
  