/* body {
    font-family: Arial, sans-serif;
    font-size: 14px;
    color: #000000;
    margin: '20px'
  } */
  
  /* Header */
  .header {
    background-color: #c7c6c6fc;
    border-top: 15px solid #000080;
    padding: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .header h1 {
    font-size: 20px;
    color: #080808;
    margin: 0;
  }
  
  .header p {
    font-size: 14px;
    color: #080808;
    margin: 0;
  }
  
  /* Bill To */
  .bill-to {
    margin-top: 40px;
  }
  
  .bill-to h2 {
    font-size: 14px;
    font-weight: bold;
    color: #000080;
    margin-bottom: 10px;
  }
  
  .bill-to p {
    margin: 0;
  }
  
  /* Table */
  /* table {
    border-collapse: collapse;
    width: 100%;
    margin-top: 40px;
  } */
  
   table th .the{
    background-color: #f2f2f2;
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  } 
  
  table td .tde {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
  
  table tr .trr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  /* Total */
  .total {
    margin-top: 20px;
    text-align: right;
  }
  
  .total p {
    margin: 0;
  }
  