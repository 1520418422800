/* InvoiceFooter.css */

.invoice-footer {
    position: relative;
    width: 100%;
    text-align: center;
    font-size: 12px;
    padding: 10px 0;
    color: #666;
    border-top: 1px solid #ddd;
    background-color: "red";
  }
  
  .footer-content span {
    display: inline-block;
    margin: 0 5px;
  }
  
  @media print {
    .invoice-footer {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
    }
  
    /* Ensure page breaks correctly without cutting off content */
    .page-break {
      break-after: always;
    }
  
    /* Allowing content to break across pages and reserving footer space */
    .content {
      display: block;
      margin-bottom: 30px; /* adjust margin to provide spacing from footer */
    }
  }
  