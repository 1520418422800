.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
table thead th.townWiseSale{
  writing-mode: vertical-lr;
}
  @media print {
    table thead th.townWiseSale{
      writing-mode: vertical-lr;
    }
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.top-bottom-margin{
  margin-top: 1%;
}

.between-rows-margin{
  margin-top: 1%;
}
.between-left-margin{
  margin-left: 1%;
}
.between-right-margin{
  margin-right: 1%;
}

.height-of-button{
  height: 38px;
}

.textbox{
  border-top: none;
  border-left: none;
  border-right: none;
}
.custom-table .MuiTableRow-root {
  height: 50px;
}

a .canvasjs-chart-credit{
display: 'none';
}
/* .select-editable {position:relative; background-color:white; border:solid grey 1px;  width:120px; height:18px;}
.select-editable select {position:absolute; top:0px; left:0px; font-size:14px; border:none; width:120px; margin:0;}
.select-editable input {position:absolute; top:0px; left:0px; width:100px; padding:1px; font-size:12px; border:none;}
.select-editable select:focus, .select-editable input:focus {outline:none;} */