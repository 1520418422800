.welcome-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(45deg, #4caf50, #2196f3);
    perspective: 1000px;
  }
  
  .welcome-text {
    transform: translateZ(100px);
    padding: 20px;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
    text-align: center;
  }
  
  h1 {
    font-size: 24px;
    color: #333;
    margin: 0;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
  